@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";


@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@font-face {

    font-family : "YABARI";
    src : url("../public/Yapari-Bold.ttf");
}
@font-face {
    font-family : "BASC";
    src : url("../public/BAHNSCHRIFT\ 6.TTF");}

.galHov{
    @apply absolute w-full h-full hover:bg-green-600  hover:bg-opacity-20 transition duration-300
}

.navdown{
@apply   absolute  mx-auto overflow-hidden     bg-white  top-0 w-full  transition-height duration-500 ease-in-out h-screen  z-20
}
.navup{
@apply  absolute  overflow-hidden  top-0   mx-auto     w-full bg-white transition-height duration-500  h-0 z-20
}
    
.faq_show{
@apply text-sm   transition-height duration-500 ease-in-out h-56 min-[100px]:h-96
}
.faq_noshow{
@apply   text-sm  transition-height duration-500 ease-in-out h-0
}
    
.form_show{
@apply container px-4 lg:py-24  min-[100px]:py-4 mx-auto text-xs   transition-height duration-500 ease-in-out opacity-100 h-full
}
.form_noshow{
@apply container px-4 lg:py-24  min-[100px]:py-4 mx-auto  text-xs   transition-height duration-500 ease-in-out h-0 opacity-0
}

.exec_show{
@apply text-xs   transition-height duration-500 ease-in-out 
}
.exec_noshow{
@apply   text-xs   transition-height duration-500 ease-in-out  h-0
}
.open{
@apply h-0 overflow-hidden transition-all duration-500
}
.close{
@apply h-full overflow-visible transition-all 
}

.noShadow{
@apply absolute inset-0 bg-gradient-to-b from-black/0 via-black/10 to-black/90 transition-all duration-300  ;
 }
.shadow{
@apply  absolute inset-0 bg-gradient-to-b from-black/70 via-black/60 to-black/70 transition-all  duration-300;
}

.scaleUp{
@apply  h-full w-full object-cover rotate-3 scale-125 transition-transform duration-500
}
.noScaleUp{
@apply h-full w-full object-cover transition-transform duration-500
}
.textUp{
@apply absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-0 transition-all duration-500
}
.textDown{
@apply absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[55%] transition-all duration-500
}

.textDowni{
@apply absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[50%] transition-all duration-500
}
.textDownii{
@apply absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[55%] transition-all duration-500
}

.textShow{
@apply  text-sm text-white mb-3 opacity-100 transition-opacity duration-300
}
.textNoShow{
@apply  text-sm text-white mb-3 opacity-0 transition-opacity duration-300
}
.anx{
@apply transform rotate-45
}
.across{
@apply transform rotate-0
}
.offscreen{
@apply  absolute py-0  w-screen -top-12 left-0 lg:group-hover:py-20  md:group-hover:py-20  bg-white  transition-all duration-300
}
.onscreen{
@apply absolute min-[100px]:py-16 md:py-20 lg:py-16  w-screen top-0 left-0 bg-white transition-all duration-300 
}
/*.slick-slide img{
@apply  h-full w-full -z-20 object-cover p-6 scale-125 transition-transform duration-500 mx-2 opacity-40
   
}
.slick-slide:not( .slick-center) {
    @apply  -z-20
}

.slick-slider{
    @apply -z-20
}

.slick-list{
 @apply -z-10 -mx-2
}
.center .slick-center .slid img  {
@apply h-full w-full object-cover z-40 lg:scale-75  min-[100px]:scale-100 hover:scale-100 opacity-100 transition-transform duration-500
}

.next-slick{
    @apply p-12 bg-green-600   rounded-full
    }
.prev-slick{
    @apply p-12 rounded-full
    } */

    .center .slick-center .slid  img  {
@apply h-full w-full object-cover z-40 lg:scale-75 min-[100px]:scale-100 hover:scale-100 opacity-100 transition-transform duration-500
}
.slick-slide img{
@apply  h-full w-full -z-20 object-cover p-6 scale-125 transition-transform duration-500 mx-2 opacity-40
   
}

.slick-prev:before, .slick-next:before  {
    content: '' !important;
  }
  